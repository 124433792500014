// Navigation.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const [activeLink, setActiveLink] = useState('/'); // Initial active link is set to '/'
  
  const handleNavLinkClick = (to) => {
    setActiveLink(to);
  };

  return (
    <nav className="navigation">
      <Link to="/" className={`nav-link ${activeLink === '/' ? 'selected' : ''}`} onClick={() => handleNavLinkClick('/')}>Kaart</Link>
      <Link to="/knmi" className={`nav-link ${activeLink === '/knmi' ? 'selected' : ''}`} onClick={() => handleNavLinkClick('/knmi')}>KNMI Pluim</Link>
      <Link to="/about" className={`nav-link ${activeLink === '/about' ? 'selected' : ''}`} onClick={() => handleNavLinkClick('/about')}>Achtergrond</Link>
      <Link to="/sponsors" className={`nav-link ${activeLink === '/sponsors' ? 'selected' : ''}`} onClick={() => handleNavLinkClick('/sponsors')}>Sponsors</Link>
      <Link to="/disclaimer" className={`nav-link ${activeLink === '/disclaimer' ? 'selected' : ''}`} onClick={() => handleNavLinkClick('/disclaimer')}>Disclaimer</Link>
    </nav>
  );
};

export default Navigation;