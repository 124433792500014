// Footer.js
import React from 'react';
// import Navigation from './Navigation.js';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <p>&copy; 2025 Erik Ekkel. Alle rechten voorbehouden.</p>
        <p></p>
      </div>
    </footer>
  );
};

export default Footer;
