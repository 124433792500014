import React, { useState, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga'; // Import ReactGA library

const BannerRotator = () => {
  const banners = useMemo (() => [
    { url: `${process.env.PUBLIC_URL}https://www.ekkel.com/wp-site/wp-content/banners/Nijdam-banner.jpg`, link: 'http://www.nijdam.com' },
    { url: `${process.env.PUBLIC_URL}https://www.ekkel.com/wp-site/wp-content/banners/groen-leeg.jpg`, link: '/sponsors' },
  ], []);

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const rotateBanners = () => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    };

    const rotationInterval = setInterval(rotateBanners, 5000);

    return () => clearInterval(rotationInterval);
  }, [banners]);

  const handleClick = (link) => {
    window.open(link, '_blank'); // Open the link in a new tab

    // Send event to Google Analytics
    ReactGA.event({
      category: 'Banner Click', // Event category
      action: 'Click', // Event action
      label: link // Event label (the URL of the clicked banner)
    });
  };

  return (
    <div
      onClick={() => handleClick(banners[currentBannerIndex].link)}
      style={{
        cursor: 'pointer',
        maxWidth: '100%',    // Optional container styling
        overflow: 'hidden',  // In case you want to hide any overflow
      }}
    >
      <img
        src={banners[currentBannerIndex].url}
        alt="Banner"
        style={{
          maxWidth: '100%',  // Scale the width to parent container
          height: 'auto',    // Maintain aspect ratio
          display: 'block',  // Remove extra whitespace below image (optional)
        }}
      />
    </div>
  );
};

export default BannerRotator;