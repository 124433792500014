// ~Sponsors.js
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import './Sponsors.css'; // Import the CSS file for styling

const Sponsors = () => {
  useEffect(() => {
    // Track page view when the component mounts
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Apply styles directly to the body for scrolling
    document.body.style.overflowY = 'auto';
    document.body.style.maxHeight = '100vh';

    // Clean up the styles when the component unmounts
    return () => {
      document.body.style.overflowY = 'initial';
      document.body.style.maxHeight = 'initial';
    };
  }, []); // Empty dependency array to run the effect only once

  return (
    <div className="sponsors-container" style={{ marginLeft: '190px', marginRight: '200px' }}>
      <div className="content">
      <h1>Sponsor mogelijkheden</h1>

        <h2>Adverteren op Ekkel.com en de IJsmeester App</h2>
   
        <h3>Bereik duizenden schaatsliefhebbers</h3>
        <p>
            Wilt u uw merk promoten bij een actief en betrokken publiek? Onze website en de populaire IJsmeester App bieden u een unieke mogelijkheid om direct in contact te komen met duizenden schaatsliefhebbers.
        </p>
        <h3>Waarom adverteren bij ons?</h3>
        <ul>
            <li><strong>Bereik uw doelgroep:</strong> In een goede winter ontvangen we gemiddeld <strong>50.000 unieke bezoekers per dag</strong> op onze website. Daarnaast is de IJsmeester App al meer dan <strong>200.000 keer gedownload</strong>.</li>
            <li><strong>Optimale zichtbaarheid:</strong> Plaats een banner op onze website of in de app en val op bij een gepassioneerd en actief publiek dat informatie zoekt over natuurijs en schaatsplekken.</li>
            <li><strong>Betrokkenheid:</strong> Onze gebruikers vertrouwen op onze platformen voor actuele informatie over waar en wanneer ze kunnen schaatsen, en uw boodschap kan precies op dat moment bij hen in beeld komen.</li>
        </ul>
        <p>
            Met een geschiedenis van succesvolle PR, vermeldingen in landelijke media, en topposities in downloadstatistieken, is adverteren bij ons een slimme keuze voor bedrijven die hun zichtbaarheid willen vergroten.
        </p>
        <p>
            Pak deze kans en word sponsor! Neem vandaag nog contact op met Jeroen Huzen via mail 
            <a href="mailto:jhuzen@gmail.com"> jhuzen@gmail.com </a> 
            om de mogelijkheden te bespreken en samen een effectieve campagne op te zetten. 
            Uw merk in beeld, op het juiste moment, bij de juiste doelgroep!
        </p>
      
      </div>

  </div>
  );
};

export default Sponsors;

